import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

export const submitJobRequest = async (input, contractWorkingDate = []) => {
	const mutation = gql`
        mutation SubmitJobRequest($input: JobRequestInput!, $contractWorkingDate: [String]) {
            SubmitJobRequest(JobRequestInput: $input, ContractWorkingDate: $contractWorkingDate) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { SubmitJobRequest }
	} = await apollo.mutate({
		mutation,
		variables: {
			input,
			contractWorkingDate
		}
	});

	return SubmitJobRequest;
};

export const getJobRequestList = async (filter = {}) => {
	const query = gql`
        query ($filter: AdvancedGlobalFilterInput) {
            GetJobRequestList(Filter: $filter) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { GetJobRequestList }
	} = await apollo.query({
		query,
		variables: {
			filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetJobRequestList;
};

export const delJobRequest = async (jobRequestId) => {
	const mutation = gql`
		mutation ($jobRequestId: [ID!]!) {
			DelJobRequest(JobRequestId: $jobRequestId)
		}
	`;

	const {
		data: { DelJobRequest }
	} = await apollo.mutate({
		mutation,
		variables: {
			jobRequestId: +jobRequestId
		}
	});

	return DelJobRequest;
};

export const changeStatusJobRequestList = async (arrJobRequestId, status) => {
	const mutation = gql`
		mutation ($arrJobRequestId: [ID!]!, $status: Int!) {
			ChangeStatusJobRequestList(ArrayJobRequestId: $arrJobRequestId, Status: $status) {
                ${JOB_REQUEST_FIELDS}
            }
		}
	`;

	const {
		data: { ChangeStatusJobRequestList }
	} = await apollo.mutate({
		mutation,
		variables: {
			arrJobRequestId,
			status: +status
		}
	});

	return ChangeStatusJobRequestList;
};

export const getJobRequest = async (jobRequestId) => {
	const query = gql`
        query($jobRequestId: ID!) {
            GetJobRequest(JobRequestId: $jobRequestId) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { GetJobRequest }
	} = await apollo.query({
		query,
		variables: {
			jobRequestId: +jobRequestId
		},
		fetchPolicy: 'no-cache'
	});

	return GetJobRequest;
};

export const getTotalJobRequestList = async (filter = {}) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput) {
			GetTotalJobRequestList(Filter: $filter)
		}
	`;

	const {
		data: { GetTotalJobRequestList }
	} = await apollo.query({
		query,
		variables: {
			filter
		},
		fetchPolicy: 'no-cache'
	});

	return GetTotalJobRequestList;
};

export const createProjectFromJobRequest = async (newProject = {}, jobRequestId = 0, supplierId = 0) => {
	const mutation = gql`
        mutation($newProject: ProjectInput!, $jobRequestId: ID!, $supplierId: ID!) {
            CreateProjectFromJobRequest(NewProject: $newProject, JobRequestId: $jobRequestId, SupplierId: $supplierId) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { CreateProjectFromJobRequest }
	} = await apollo.mutate({
		mutation,
		variables: {
			newProject,
			jobRequestId: +jobRequestId,
			supplierId: +supplierId
		}
	});

	return CreateProjectFromJobRequest;
};

export const updateJobRequestTargetProjectId = async (targetProjectId = 0, jobRequestId = 0) => {
	const mutation = gql`
        mutation($targetProjectId: ID!, $jobRequestId: ID!) {
            UpdateJobRequestTargetProjectId(TargetProjectId: $targetProjectId, JobRequestId: $jobRequestId) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { UpdateJobRequestTargetProjectId }
	} = await apollo.mutate({
		mutation,
		variables: {
			targetProjectId: +targetProjectId,
			jobRequestId: +jobRequestId
		}
	});

	return UpdateJobRequestTargetProjectId;
};

export const addJobRequestOnProject = async (jobRequestId = 0) => {
	const mutation = gql`
        mutation($jobRequestId: ID!) {
            AddJobRequestOnProject(JobRequestId: $jobRequestId) {
                ${JOB_REQUEST_FIELDS}
            }
        }
    `;

	const {
		data: { AddJobRequestOnProject }
	} = await apollo.mutate({
		mutation,
		variables: {
			jobRequestId: +jobRequestId
		}
	});

	return AddJobRequestOnProject;
};

const JOB_REQUEST_FIELDS = `
  id
  createdAt
  updatedAt
  projectId
  targetProjectId
  requestDate
  function
  department
  userId
  userCreated
  supplierId
  status
  offerStatus
  invitationStatus
  userOnBoard
  specifyWorkingDays
  jobRequestNumber
  statusName
  depAuthorization
  supplierName
  fullName
  documentMemoDeal
  productionType
  projectName
  defaultLicenseId
  token
  project {
    titre
  }
  supplier {
    name
    contactName
    email
    phone
  }
`;
